import logo from "./logo.svg";
import "./App.css";
import Image from "./component/Image";
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Image />} />
      </Routes>
    </div>
  );
}

export default App;
