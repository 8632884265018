
import apiInstance from "./interceptor/axios";
export const createEvents = async (data) => {
    let ApiResponse = await apiInstance.post("leads", data);
    return ApiResponse;
}

export const getImages = async (datas) => {
    let ApiResponse = await apiInstance.post("users/get/event/image", datas);
    return ApiResponse
}
export const getAllImages = async (datas) => {
    let ApiResponse = await apiInstance.post("admin/getevent/imaages", datas);
    return ApiResponse
}
