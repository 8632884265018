import React, { useState, useRef, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import logo from "../image/Fsv2.png";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
  faArrowLeft,
  faArrowRight,
  faDownload,
  faEllipsisV,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import {
  EnquireInitValues,
  EnquireSchema,
} from "../validations/enquireValidation";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import { Select, Modal, DatePicker } from "antd";
import { getImages, createEvents, getAllImages } from "../enquireService";

function Image() {
  let location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const event = queryParams.get("event");
  const user = queryParams.get("user");
  const host = queryParams.get("host");
  // const { event, user } = useParams();
  // console.log(event);
  // console.log(user);
  const [userData, setUserData] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [showText, setShowText] = useState(false);

  // {isModalOpen}
  //         onCancel={handleCancel}

  const [isModalOpen, setmodalOpen] = useState(false);
  function open() {
    setmodalOpen(true);
  }
  function handleCancel() {
    setmodalOpen(false);
  }

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openPreview = (imageSrc) => {
    setSelectedImage(imageSrc);
    console.log(imageSrc);
  };

  const closePreview = () => {
    setSelectedImage(null);
    setMenuOpen(!menuOpen);
  };

  const downloadAllImages = async () => {};

  useEffect(async () => {
    console.log(host);
    try {
      if (host === "yes") {
        let ser = await getAllImages({ eventId: event, userId: user });
        console.log(ser.data);
        setImages(ser.data[0].images);
      } else {
        let ser = await getImages({ eventId: event, userId: user });
        console.log(ser.data);
        setImages(ser.data.viewImage);
        setUserData(ser.data);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
    // if (!getEventPhotos.data.success){
    //   setImages(getEventPhotos.data.images);
    // }
  }, []);

  const downloadImage = async () => {
    try {
      const response = await fetch(selectedImage);
      const blob = await response.blob();
      saveAs(blob, "image.png");
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  // const shareImage = async () => {
  //   try {
  //     if (navigator.share) {
  //       const blob = await fetch(selectedImage).then((response) => response.blob());
  //       const filesArray = [new File([blob], 'image.png', { type: 'image/png' })];
  //       const shareData = {
  //         files: filesArray,
  //       };
  //       await navigator.share(shareData);
  //     } else {
  //       alert('Sharing not supported on this browser.');
  //     }
  //   } catch (error) {
  //     console.error('Error sharing:', error);
  //   }
  // };

  // function handleleft(){
  //   if (image !== null) {
  //     // Calculate the index of the previous image
  //     const prevIndex = (image - 1 + images.length) % images.length;
  //     // Open the preview of the previous image
  //     openPreview(images[prevIndex].src, prevIndex);
  //   }
  // }
  // const nextImage = () => {
  //   setPreviewIndex((prevIndex) => (prevIndex + 1) % images.length); // Move to the next image circularly
  // };

  // const prevImage = () => {
  //   setPreviewIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); // Move to the previous image circularly
  // };

  const shareImage = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Shared Image",
          text: "Check out this image!",
          url: selectedImage,
        });
      } else {
        alert("Sharing not supported on this browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  // const [formdata, setformdata] = useState(initial)

  function handlechange() {
    // setformdata({ ...formdata, [event.target.name]: event.target.value })
    // if (event.target.name === "category" && event.target.value === "others") {
    //   handleOther(event.target.name, event.target.value);
    // }
  }

  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   const menu = document.querySelector('.menu');
    //   if (menu && !menu.contains(event.target)) {
    //     setMenuOpen(false);
    //   }
    // };
    // window.addEventListener('click', handleClickOutside);
    // return () => {
    //   window.removeEventListener('click', handleClickOutside);
    // };
  }, []);

  const handleNext = () => {
    const newIndex = (imageIndex + 1) % images.length;
    setSelectedImage(images[newIndex]);
    setImageIndex(newIndex);
  };

  const handlePrevious = () => {
    const newIndex = (imageIndex - 1 + images.length) % images.length;
    setSelectedImage(images[newIndex]);
    setImageIndex(newIndex);
  };

  const forms = useFormik({
    initialValues: EnquireInitValues,
    validationSchema: EnquireSchema,
    onSubmit: (values) => {
      submitForms(values);
    },
  });
  const handleOther = (value, label) => {
    if (label.value === "Others") {
      setShowText(true);
    } else if (label.value !== "Others") {
      setShowText(false);
      forms.setFieldValue("other", "");
    }
    forms.setFieldValue("eventCategory", label.value);
  };

  const submitForms = async (values) => {
    console.log(values);
    try {
      let data = await createEvents(values);
      console.log(data);
      handleCancel();
    } catch (error) {
      console.error(error);
    }
    // try {

    //   const data = { ...values };

    //   if (values.eventCategory === "Others") {
    //     data.eventCategory = values.other;
    //   }

    //   const finalData =
    //     values.eventCategory === "Others"
    //       ? Object.fromEntries(
    //         Object.entries(data).filter(([key]) => key !== "other")
    //       )
    //       : Object.fromEntries(
    //         Object.entries(data).filter(([key]) => key !== "other")
    //       );

    //   console.log(finalData, "ssss");
    //   // let val = await createEvents(finalData);
    //   handleCancel();
    //   forms.resetForm();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <div className="">
      <div className="flex md:flex-row flex-col justify-center items-center relative ">
        <img src={logo} className="md:w-[20%] w-[50%]" alt=""></img>
        <div className="md:absolute right-10 mt-5">
          <div className="flex flex-row gap-7">
            {" "}
            <div className="text-sm bg-first rounded-md text-white h-9 hover:bg-second duration-200 shadow-sm shadow-first hover:shadow-second overflow-hidden">
              <a
                href={userData?.zipURL}
                download={`${event?.split("-")[0]}_image.zip`}
                className="flex items-center justify-center w-full h-full px-5 py-1 bg-black"
              >
                Download All
              </a>
            </div>
            <button
              onClick={open}
              className="px-5 py-1 text-sm bg-first rounded-md text-white h-9 hover:bg-second duration-200 shadow-sm shadow-first hover:shadow-second"
            >
              Enquire
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 p-10">
        {images &&
          images.map((image, index) =>
            images.length > 0 ? (
              <div key={index}>
                <img
                  className="object-cover transition-all al object-center w-full h-64 max-w-full rounded-lg cursor-pointer"
                  src={image}
                  alt="gallery-photo"
                  onClick={() => {
                    openPreview(image);
                    setImageIndex(index);
                  }}
                />
              </div>
            ) : (
              <p className=" font-semibold h-fit">
                Your event's moments will be received shortly!
              </p>
            )
          )}
      </div>
      {selectedImage !== null && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-75 flex justify-center items-center">
          <button
            className="absolute top-[50%] left-10 w-10  text-white text-xl"
            onClick={handlePrevious}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            className="absolute top-[50%] right-10 w-10  text-white text-xl"
            onClick={handleNext}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
          <FontAwesomeIcon
            icon={faEllipsisV}
            className="menu absolute right-10 top-8 mt-5 cursor-pointer text-white w-7 h-7 hover:border-2 rounded-full px-2 py-2"
            onClick={toggleMenu}
          />
          {menuOpen && (
            <div className="absolute top-10 right-12 bg-white p-4 rounded shadow">
              <button
                onClick={shareImage}
                className="block mb-2 hover:bg-gray-300 w-full text-start py-2 px-1"
              >
                <FontAwesomeIcon icon={faShare} /> Share
              </button>
              <div>
                <a
                  className="block mb-2 hover:bg-gray-300 w-full text-start py-2 px-1 z-30"
                  href={selectedImage}
                  download
                >
                  <FontAwesomeIcon icon={faDownload} /> Download
                </a>
              </div>
              <button
                className="block mb-2 hover:bg-gray-300 w-full text-start py-2 px-1"
                onClick={closePreview}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back to Gallery
              </button>
            </div>
          )}
          <div className="max-w-screen-lg w-full">
            <img
              className="object-contain w-full h-[80vh]"
              src={selectedImage}
              alt="preview"
            />

            {/* <button
              className="absolute top-[50%] left-10 w-10  text-white text-xl"
              onClick={()=>openPreview(images[image+1].src)}
            ><FontAwesomeIcon icon={faArrowLeft} className='size-10' ></FontAwesomeIcon>
              
            </button> */}
          </div>
        </div>
      )}
      <div className="flex justify-center items-center pb-5">
        <p className="pt-2 text-sm">Powered By</p>
        <img src={logo} className="w-28" alt=""></img>
      </div>

      <Modal
        title={"Enquire"}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={false}
      >
        <div className="w-full flex flex-col gap-3 items-center mt-10 h-fit">
          <Select
            showSearch
            className="w-[90%] h-[40px] mb-6 text-sm"
            placeholder="Select Event Category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            // filterSort={(optionA, optionB) =>
            //   (optionA?.label ?? "")
            //     .toLowerCase()
            //     .localeCompare((optionB?.label ?? "").toLowerCase())
            // }
            options={[
              {
                value: "Wedding",
                label: "Wedding",
              },
              {
                value: "Birthday",
                label: "Birthday",
              },
              {
                value: "Anniversary",
                label: "Anniversary",
              },
              {
                value: "New year",
                label: "New year",
              },
              {
                value: "Others",
                label: "Others",
              },
            ]}
            // value={forms.values.eventCategory}
            onChange={(value, label) => handleOther(value, label)}
          />
          {forms.values.eventCategory === "Others" && (
            <input
              type="text"
              placeholder="Others"
              className={`${
                forms.errors.other && forms.touched.other
                  ? "border-red-500 w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6 text-sm"
                  : "w-[90%]  rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6 text-sm"
              }`}
              name="other"
              id="other"
              onBlur={forms.handleBlur}
              value={forms.values.other}
              onChange={forms.handleChange}
            />
          )}
          {/* )} */}
          <input
            type="text"
            onChange={forms.handleChange}
            value={forms.values.eventName}
            onBlur={forms.handleBlur}
            placeholder="Event Name"
            className={`${
              forms.errors.eventName && forms.touched.eventName
                ? "border-red-500 w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
                : "w-[90%]  rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
            }`}
            name="eventName"
            id="eventName"
          />
          <input
            type="text"
            onChange={forms.handleChange}
            value={forms.values.eventLocation}
            onBlur={forms.handleBlur}
            placeholder="Event Location"
            className={`${
              forms.errors.eventLocation && forms.touched.eventLocation
                ? "border-red-500 w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
                : "w-[90%]  rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
            }`}
            name="eventLocation"
            id="eventLocation"
          />
          <DatePicker
            format="DD-MM-YYYY"
            name="eventDate"
            placeholder="Event date"
            id="eventDate"
            className={`${
              forms.errors.eventDate && forms.touched.eventDate
                ? "border-red-500 w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6 text-sm"
                : " w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6 text-sm"
            } `}
            value={
              forms.values.eventDate ? moment(forms.values.eventDate) : null
            }
            onChange={(date) =>
              forms.setFieldValue("eventDate", date ? date.toDate() : null)
            }
          />
          <input
            type="text"
            placeholder="Name"
            className={`${
              forms.errors.hostName && forms.touched.hostName
                ? "border-red-500 w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
                : "w-[90%]  rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
            }`}
            onBlur={forms.handleBlur}
            value={forms.values.hostName}
            onChange={forms.handleChange}
            name="hostName"
            id="hostName"
          />
          <input
            type="email"
            placeholder="Email"
            className={`${
              forms.errors.hostEmail && forms.touched.hostEmail
                ? "border-red-500 w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
                : "w-[90%]  rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
            }`}
            name="hostEmail"
            id="hostEmail"
            onBlur={forms.handleBlur}
            value={forms.values.hostEmail}
            onChange={forms.handleChange}
          />
          <input
            onBlur={forms.handleBlur}
            value={forms.values.hostWhatsappNumber}
            onChange={forms.handleChange}
            type="HostNumber"
            placeholder="WhatsApp Number"
            className={`${
              forms.errors.hostWhatsappNumber &&
              forms.touched.hostWhatsappNumber
                ? "border-red-500 w-[90%] rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
                : "w-[90%]  rounded-md p-2 -mt-3 border-2 bg-gray-200 mb-6"
            }`}
            name="hostWhatsappNumber"
            id="hostWhatsappNumber"
          />
        </div>
        <div className="flex justify-center">
          <button
            type="button"
            onClick={forms.handleSubmit}
            className="px-4 py-1 text-sm bg-first rounded-md text-white h-9 hover:bg-second duration-200 shadow-sm shadow-first hover:shadow-second"
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default Image;
