import * as Yup from "yup";


export const EnquireSchema = Yup.object({
    eventName: Yup.string().required("Enter Event Name"),
    eventLocation: Yup.string().required("Enter Event Location"),
    eventDate: Yup.string().required("Enter Event Date"),
    hostName: Yup.string().required("Enter host name"),
    hostEmail: Yup.string().email().required("Enter host email"),
    hostWhatsappNumber: Yup.string().required("Enter whatsapp number"),
    other: Yup.string().when('eventCategory', (value) => {
        if (value == 'Others') {
            return Yup.string().required("Enter other event")
        } else {
            return Yup.string()
        }
    }),
})


export const EnquireInitValues = {
    eventName: "",
    eventLocation: "",
    eventDate: "",
    eventCategory: "",
    hostName: "",
    hostEmail: "",
    hostWhatsappNumber: "",
    other: ""
}
